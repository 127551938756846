const status = {
  title: "Beskrivelse av de forskjellige statiske statusene.",
  description: "Velg en status for å slå av autostatus på objektet.",
  translationPath: "items.status",
  options: [
    {
      name: "inactive",
      description: "Objektet er for øyeblikket ikke aktivt."
    },
    {
      name: "outreach",
      description: "Oppdrag vi får av namsmannen eller inkassoselskaper."
    },
    {
      name: "still",
      description:
        "Vi lagrer objektet for NAV og gir det til en ny bruker når det er klart. Dette gjelder vanligvis nyere biler som NAV mener kan gjenbrukes istedenfor å bli solgt."
    },
    {
      name: "wrecked",
      description: "Vrakbil, andre regler vil gjelde for salget"
    },
    {
      name: "saleexcluded",
      description: "Objektet kan ikke selges for øyeblikket."
    },
    {
      name: "salestopped",
      description: "Salget stoppes, noen papirer er ikke i orden."
    },
    {
      name: "archived",
      description:
        "Når et objektsalg er fullført, pakket og levert, blir objektet arkivert. Vi arkiverer også objekter som blir avsluttet på andre måter, for eksempel returnert til agenten, namsmannen eller lignende.",
      description2:
        "Arkivering sikrer at alle detaljer og historikk om objektet bevares for fremtidig referanse."
    },
    {
      name: "museum",
      description:
        "Objekter som kun skal inkluderes i vårt museum og ikke er en del av vårt auksjonssystem."
    },
    {
      name: "Auto-status",
      value: "onitsway",
      description:
        "Objektet endrer status automatisk basert på verdier i de ulike modulene.",
      description2:
        "Standardverdi. Kan kun overstyres ved å velge et av de andre statusene."
    }
  ]
};

const valuationSupplier = {
  title: "Definisjon av de ulike verdivurderingsleverandørene",
  description:
    "<strong>OBS!</strong> Ved offentlig oppdragstype foreligger det som regel et takstoppdrag fra før av.",
  translationPath: null,
  options: [
    {
      name: "Ikke spesifisert",
      value: "",
      description2: "yoyo",
      description: "En annen leverandør enn NAF, Viking eller Norgesttakst."
    },
    {
      name: "NAF",
      description: "Vi får levert takst fra NAF."
    },
    {
      name: "Viking",
      description: "Vi får levert takst fra Viking."
    },

    {
      name: "Norgestakst",
      description:
        "Takstmann tar fjerntakst av objektet - ut fra bilder vi sender inn."
    },
    {
      name: "Skal ikke takseres",
      description:
        "Alle felt vil bli deaktivert (inntil ny takstleverandør velges)"
    }
  ]
};

const preparationFiles = {
  title: "Bilder og dokumenter i klargjøringsmodulen",
  translationPath: "items.files",
  options: [
    {
      name: "photos",
      description: "Bilder som kan brukes til salgsannonsen."
    },
    {
      name: "documentationPhotos",
      description:
        "Bilder som blir brukt til dokumentasjon. For f.eks. skader, feil og mangler."
    },
    {
      name: "documents",
      description: "Øvrige dokumenter"
    },
    {
      name: "various",
      description: "Hva som helst som kan være relevant for objektet"
    }
  ]
};

const infoFiles = {
  title: "Dokumenter i infomodulen",
  description: "Her er det viktig å velge riktig filtype!",
  translationPath: "items.files",
  options: [
    {
      name: "various",
      description: "Generelle filer som har tilknytning til objektet"
    },
    {
      name: "autosys",
      description: "Autosysrapport fra vegvesenet"
    },
    {
      name: "documents",
      description: "Relevante dokumenter"
    },
    {
      name: "legitimasjon",
      description:
        "ID-kort, passport, førerkort eller annen gyldig legitimasjon"
    },
    {
      name: "salgsmelding",
      description:
        "Signert salgsmelding som gir Stadssalg fullmakt til å selge objektet"
    },
    {
      name: "frivilligskjema",
      description:
        "Dokument for omregistrering av kjøretøy som er frivillig tilbakelevert"
    },
    {
      name: "abandonering",
      description: "Et konkursdokument."
    },
    {
      name: "protokoll",
      description:
        "Selve salgsprotokollen vi får fra Namnsmann. Også noe vi bruker for omregistrering på offentlige saker. Protokollen MÅ foreligge før salg av offentlige objekter."
    }
  ]
};

const valuationFiles = {
  title: "Dokumenter i takstmodulen",
  description:
    "Takstutsenderen er avhengig av hvilke taksttyper du laster opp. Velg med omhu!",
  translationPath: "items.files",
  options: [
    {
      name: "various",
      description:
        "Øvrige dokumenter relatert til objektet.\n<strong>INGEN VERDIVURDERING SKAL LASTES OPP HER!</strong>"
    },
    {
      name: "annenbruktbiltest",
      description: "Alle bruktbiltester som ikke er NAF eller Viking."
    },
    {
      name: "nafbruktbiltest",
      description: "Velg denne dersom det foreligger en bruktbiltest fra NAF"
    },
    {
      name: "vikingbruktbiltest",
      description: "Velg denne dersom det foreligger en bruktbiltest fra Viking"
    },
    {
      name: "sladdetbruktbiltest",
      description: "For publikum, hvis aktuelt."
    },
    {
      name: "annenverdivurdering",
      description: "Alle verdivurderinger som ikke er NAF eller Viking."
    },
    {
      name: "nafverdivurdering",
      description: "Velg denne dersom det foreligger en verdivurdering fra NAF"
    },
    {
      name: "vikingverdivurdering",
      description:
        "Velg denne dersom det foreligger en verdivurdering fra Viking"
    },
    {
      name: "nafunormal",
      description: "Rapport på unormal slitasje fra NAF"
    },
    {
      name: "vikingunormal",
      description: "Rapport på unormal slitasje fra Viking"
    }
  ]
};

const saleFiles = {
  title: "Dokumneter i takstmodulen",
  translationPath: "items.files",
  options: [
    {
      name: "various",
      description: "Øvrige dokumenter relatert til salget"
    }
  ]
};

const economyFiles = {
  title: "De ulike dokumenttypene i økonomimodulen",
  description: "Endelige dokumenter for objektet lastes opp her.",
  translationPath: "items.files",
  options: [
    {
      name: "various",
      description: "Øvrige dokumenter"
    },
    {
      name: "omkostningsoppgave",
      description: "Omkostningsoppgaven kan hentes og lastes opp her."
    },
    {
      name: "fakturaer",
      description: "Alle fakturaer knyttet til salgsprosessen"
    },
    {
      name: "oppgjørsdokument",
      description: "Oppgjørsdokumentet til kunden"
    }
  ]
};

const museumFiles = {
  title:
    "Hva betyr de ulike dokumenttypene som kan lastes opp i museumsmodulen?",
  description:
    "Dette er en beskrivelse av de ulike dokumenttypene som kan lastes opp i museumsmodulen.",
  translationPath: "items.files",
  options: [
    {
      name: "photos",
      description: "Minimum tre bilder per museumsobjekt"
    },
    {
      name: "documents",
      description: "Historiske dokumenter for objektet, eierpapirer og lignende"
    }
  ]
};

const accountingCategory = {
  title: "Beskrivelse av de forskjellige regnskapskategoriene",
  description:
    "<strong>OBS! Det er noen viktige unntak:</strong><p>Hittegods og tvangssalg er <u>alltid</u> <strong>Salg kommisjon - AVG. FRITT</strong>.</p><p>Traktorer er mva-pliktig selvom det har regnr og dermed kan ATV også være mva-pliktig om de er registrert som traktor så der må dere undersøke eller spørre om hva som er rett.",
  translationPath: "items.accountingCategory",
  options: [
    {
      name: 1,
      description:
        "Hvis det er en av firmabilene til Stadssalg så skal det velges Salg egeneide biler AVG Fritt.",
      description2:
        "<ul>" +
        "<li>Dette refererer til inntekt opptjent av kommisjonær (den som selger varer eller tjenester på vegne av en annen part) som ikke er underlagt merverdiavgift (MVA).</li>" +
        "<li>Det kan være tilfeller der kommisjonæren selger varer eller tjenester på vegne av en kommitent, men der selve transaksjonen ikke er MVA-pliktig.</li>" +
        "<li>Her rapporterer kommisjonæren inntektene fra salget, men inntekten er fritatt fra MVA.</li>" +
        "</ul>"
    },
    {
      name: 2,
      description:
        "Ved ekstern oppdragsgiver:  Objektet har regnr og betaler omregistreringsavgift, dette gjelder stort sett alle kjøretøy som vi får inn utenom f eks traktor, så skal man velge: Salg kommisjon AVG.FRITT",
      description2:
        "<ul>" +
        "<li>Dette refererer til salg utført av en kommitent (den som eier varene eller tjenestene som selges) der salget er underlagt MVA.</li>" +
        "<li>Kommitenten, som eier produktene eller tjenestene, har ansvaret for å betale MVA på salgene.</li>" +
        "<li>I dette tilfellet vil regnskapet reflektere både inntektene fra salget og den tilhørende MVA-forpliktelsen.</li>" +
        "</ul>"
    },
    {
      name: 120,
      description:
        "Vi benytter ikke denne og vil bli fjernet fra regnskapssystemet."
    },
    {
      name: 610,
      description:
        "Ved ekstern oppdragsgiver: Er det løsøre, gravemaskiner, hjullastere andre ting som ikke har regnr så skal det betales moms på dette og derfor må man velge: <strong>Salg kommitent - AVG. PLIKTIG</strong>",
      description2:
        "<ul>" +
        "<li>Dette refererer til inntektene fra salg av objekter eller varer som en bedrift eier, hvor salget ikke er underlagt merverdiavgift (MVA).</li>" +
        "<li>Det kan være ulike grunner til at salget er avgiftsfritt. For eksempel, enkelte typer varer eller tjenester kan være unntatt fra MVA, eller bedriften kan være i en bransje hvor MVA ikke gjelder.</li>" +
        "<li>Når bedriften selger disse objektene, rapporterer den inntektene fra salget, men uten å legge til eller innkreve MVA.</li>" +
        "</ul>"
    },
    {
      name: 612,
      description:
        "Er det Stadssalg sine eiendeler, men det er ikke biler og andre ting med regnr så skal man velge <strong>Salg egeneide objekter AVG. PLIKTIG</strong>",
      description2:
        "<ul>" +
        "<li>Dette omhandler inntekter fra salg av objekter eller varer som bedriften eier, der salget er underlagt MVA.</li>" +
        "<li>I dette tilfellet, når bedriften selger disse objektene, må den legge til MVA på salgsprisen. Denne MVA-en skal rapporteres og betales til skattemyndighetene.</li>" +
        "<li>Dette gjelder typisk for de fleste salg av varer og tjenester i bedrifter som er MVA-registrert og opererer i avgiftspliktige sektorer.</li>" +
        "</ul>"
    }
  ]
};

const repossessionType = {
  title: "Definisjoner av de ulike oppdragstypene",
  description:
    "<strong>VÆR OBS</strong> - noen av de utdypede tekstene trenger en oppdatering. Det er ikke sikkert at de er helt korrekte.",
  translationPath: "items.repossessionType",
  options: [
    {
      name: "forfeitureToGov",
      description:
        "Dette er en form for straff som innebærer at staten tar kontroll over eiendeler som er ervervet gjennom ulovlige midler eller aktiviteter."
    },
    {
      name: "valuationOrderNAV",
      description:
        "Takstoppdrag for NAV hvor bruker beholder bilen frem til Gjeldsoppgjør er ferdigstilt."
    },
    {
      name: "publicNotReturned",
      description:
        "Skal offentlig tilbakeleveres av namsmannen, men den er ikke tilbakelevert ennå."
    },
    {
      name: "reserved",
      description:
        "Spesielt brukt for NAV-objekter. NAV selv setter sine objekter som Reserverte."
    },
    {
      name: "voluntary",
      description:
        "Vi selger for en bank hvor eier av bilen har levert inn denne frivillig til oss, gjerne via et inkassoselskap.",
      description2:
        "<ul>" +
        "<li><b>Frivillig Innlevering:</b> Eieren av bilen har valgt å overgi kjøretøyet frivillig til banken eller inkassoselskapet. Dette er ofte et resultat av økonomiske utfordringer hvor eieren ikke kan oppfylle sine betalingsforpliktelser, som bilens avdrag eller lån.</li>" +
        "<li><b>Bankens Rolle:</b> Banken er typisk kreditoren som bilen er finansiert gjennom. Når eieren ikke kan betale lånet, tilbyr banken alternativet om frivillig innlevering som en måte å delvis oppfylle gjeldsforpliktelsene på.</li>" +
        "<li><b>Inkassoselskapets Involvering:</b> Inkassoselskaper blir ofte involvert for å håndtere prosessen med frivillig innlevering. De fungerer som et mellomledd mellom bilens eier og banken og hjelper til med å organisere overleveringen av kjøretøyet.</li>" +
        "<li><b>Salgsprosessen:</b> Når kjøretøyet er innlevert frivillig, blir det deretter solgt. Salget utføres ofte av banken eller en tredjepart som er spesialisert på slike transaksjoner. Målet er å hente inn så mye som mulig av det utestående beløpet på lånet.</li>" +
        "<li><b>Reduksjon av Gjeldsbyrden:</b> Salg av kjøretøyet hjelper til med å redusere den gjeldsbyrden som låntakeren har. Avhengig av salgssummen og lånebeløpet, kan det hende at hele eller deler av gjelden blir dekket.</li>" +
        "<li><b>Økonomiske og Juridiske Implikasjoner:</b> Prosessen med frivillig innlevering og etterfølgende salg har både økonomiske og juridiske implikasjoner for låntakeren. Det er viktig at alle parter forstår vilkårene og konsekvensene av en slik avtale.</li>" +
        "<li><b>Alternativ til Tvungen Beslagleggelse:</b> Frivillig innlevering anses ofte som et mer gunstig alternativ sammenlignet med tvungen beslagleggelse, da det vanligvis involverer færre juridiske skritt og kan ha mindre negativ innvirkning på låntakerens kredittverdighet.</li>" +
        "</ul>"
    },
    {
      name: "notApplicable",
      description:
        "Hvis ingenting annet matcher så setter man denne som en foreløpig oppdragstype og spør noen om hjelp."
    },
    {
      name: "bailiff",
      description:
        "Vi selger for en bank, men debitor vil ikke samarbeide. Dermed må saken gå via namsmannen som tilbakeleverer bilen til banken.",
      description2:
        "<ul>" +
        "<li><b>Debitors Manglende Samarbeid:</b> I denne situasjonen nekter debitor (låntakeren) å samarbeide med banken angående tilbakebetaling av gjeld eller andre avtaler knyttet til finansiering av bilen.</li>" +
        "<li><b>Innblanding av Namsmannen:</b> Når en debitor ikke samarbeider, blir saken eskalert til namsmannen, som er en offentlig myndighetsperson ansvarlig for tvangsfullbyrdelse. Namsmannens oppgave er å håndheve lovlige krav om gjeldsinnkreving.</li>" +
        "<li><b>Tvangsfullbyrdelse:</b> Namsmannen vil gjennomføre en tvangsfullbyrdelse, som kan innebære tilbakelevering av bilen til banken. Dette skjer ofte etter en rettslig prosess hvor namsmannen har fått fullmakt til å ta kontroll over bilen.</li>" +
        "<li><b>Bankens Rolle:</b> Banken, som kreditor, mottar kjøretøyet fra namsmannen. Dette skjer ofte etter at alle juridiske krav og prosesser er oppfylt.</li>" +
        "<li><b>Salgsprosessen:</b> Etter å ha mottatt kjøretøyet, organiserer banken salget av bilen. Dette er ofte gjort for å hente inn utestående beløp på lånet som debitor ikke har betalt.</li>" +
        "<li><b>Reduksjon av Gjeld:</b> Salget av kjøretøyet bidrar til å redusere debitors gjeld til banken. Avhengig av salgssum og gjeldens størrelse, kan dette enten helt eller delvis dekke gjelden.</li>" +
        "<li><b>Juridiske Konsekvenser for Debitor:</b> Denne prosessen kan ha betydelige juridiske og finansielle konsekvenser for debitor, inkludert påvirkning på kredittverdigheten.</li>" +
        '<li><b>Mer Formalisert og Tvungen Prosess:</b> I motsetning til en frivillig overlevering, er en "Offentlig" salgsprosess mer formalisert og tvungen, der myndighetene griper direkte inn i situasjonen.</li>' +
        "</ul>"
    },
    {
      name: "bankruptLeasing",
      description:
        "Vi selger et leaset objekt for en bank hvor leasingtaker er gått konkurs.",
      description2:
        "<ul>" +
        "<li><b>Konkurs av Leasingtaker:</b> I denne situasjonen har en person eller et selskap som har leaset et objekt (f.eks. en bil, maskineri, etc.) gått konkurs. Dette betyr at leasingtakeren ikke lenger kan oppfylle sine finansielle forpliktelser under leasingavtalen.</li>" +
        "<li><b>Bankens Rolle som Kreditor:</b> Banken, i dette tilfellet, er ofte kreditor og eier av det leasete objektet. Når leasingtaker går konkurs, tar banken tilbake kontroll over objektet.</li>" +
        "<li><b>Håndtering av Konkurs:</b> Konkursprosessen håndteres gjennom juridiske kanaler, der en konkursforvalter eller lignende myndighetsperson blir utnevnt for å håndtere debitors eiendeler og gjeld.</li>" +
        "<li><b>Salg av Leaset Objekt:</b> For å dekke utestående krav, organiserer banken eller konkursforvalteren salg av det leasete objektet. Målet er å generere inntekter for å betale tilbake så mye som mulig av den utestående gjelden.</li>" +
        "<li><b>Reduksjon av Gjeldsbyrden:</b> Salget bidrar til å redusere den totale gjeldsbyrden som følge av leasingtakerens konkurs. Avhengig av salgssummen og størrelsen på den utestående gjelden, kan dette bidra til å dekke hele eller deler av kravet.</li>" +
        "<li><b>Juridiske og Finansielle Konsekvenser:</b> Konkursprosessen har betydelige juridiske og finansielle konsekvenser for leasingtakeren. Den påvirker også hvordan banken kan gjenvinne midler fra det leasete objektet.</li>" +
        "<li><b>Markedsvurdering:</b> Verdien av det leasete objektet vurderes i markedet for å fastsette en passende salgspris. Dette er viktig for å maksimere inntektene fra salget og redusere tapet for banken.</li>" +
        "</ul>"
    },
    {
      name: "bankruptLoan",
      description:
        "Vi selger et objekt for en bank hvor låntaker er gått konkurs.",
      description2:
        "<ul>" +
        "<li><b>Konkurs av Låntaker:</b> Denne situasjonen oppstår når en låntaker, som har tatt opp lån sikret med salgspant i en eiendel (for eksempel en bil, utstyr, eller eiendom), går konkurs. Dette innebærer at låntakeren ikke lenger kan oppfylle sine økonomiske forpliktelser.</li>" +
        "<li><b>Salgspant som Sikkerhet:</b> Salgspant er en rettighet banken har til å ta kontroll over og selge den pantsatte eiendelen for å dekke låntakerens gjeld. Det fungerer som sikkerhet for lånet.</li>" +
        "<li><b>Bankens Rolle:</b> Som kreditor og innehaver av salgspanten, har banken retten til å ta besittelse av og selge det pantsatte objektet i tilfelle låntakerens konkurs.</li>" +
        "<li><b>Håndtering av Konkurs:</b> Konkursprosessen administreres gjennom rettslige kanaler. En konkursforvalter blir vanligvis utnevnt for å håndtere låntakerens gjeld og eiendeler.</li>" +
        "<li><b>Salgsprosess:</b> Banken, ofte i samarbeid med konkursforvalteren, organiserer salget av det pantsatte objektet. Målet er å hente inn så mye som mulig av det utestående lånebeløpet.</li>" +
        "<li><b>Reduksjon av Gjeldsbyrden:</b> Inntektene fra salget brukes til å betale ned låntakerens gjeld til banken. Hvis salgssummen overstiger gjelden, kan eventuelle overskytende midler distribueres til andre kreditorer eller til låntakeren.</li>" +
        "<li><b>Juridiske Implikasjoner:</b> Prosessen har juridiske konsekvenser for alle involverte parter. Det er viktig at salget av salgspantobjektet håndteres i samsvar med gjeldende lover og forskrifter.</li>" +
        "<li><b>Markedsvurdering:</b> Det er viktig å fastsette en markedsbasert salgspris for objektet for å sikre en rettferdig og effektiv salgsprosess, og for å maksimere de innsamlede midlene.</li>" +
        "</ul>"
    },
    {
      name: "shippingStorage",
      description:
        "Oppdrag hvor vi skal frakte og/eller lagre et objekt, men ikke selge det. F eks oppdrag fror Skatteetaten.",
      description2:
        "<ul>" +
        "<li><b>Transport av Objekter (Frakt):</b> Dette innebærer å flytte et objekt fra ett sted til et annet. Transporten må utføres på en sikker og effektiv måte, ofte under spesifikke regler og vilkår, spesielt når det gjelder verdifulle eller sensitive objekter.</li>" +
        "<li><b>Lagring av Objekter:</b> Oppdraget kan også innebære lagring av objekter. Dette krever sikker og adekvat oppbevaring for å beskytte objektene mot skader, tyveri, eller andre risikoer. Lagringsfasiliteter må være egnet for den type objekter som lagres, og i enkelte tilfeller kreves spesielle forhold som klimakontroll eller sikkerhetsforanstaltninger.</li>" +
        "<li><b>Oppdrag for Skatteetaten:</b> I noen tilfeller kan slike oppdrag komme fra Skatteetaten. Dette kan være i forbindelse med håndtering av beslaglagte eiendeler, administrasjon av eiendeler i påvente av juridiske avgjørelser, eller lignende situasjoner.</li>" +
        '<li><b>Ingen Salgsintensjon:</b> En viktig aspekt ved "Frakt/Lagring" oppdrag er at det ikke er noen intensjon om salg av objektet. Oppdragets fokus er på transport og oppbevaring.</li>' +
        "<li><b>Logistikk og Koordinering:</b> Effektiv logistikk og koordinering er essensielt, spesielt når det gjelder å flytte og lagre objekter over lengre perioder eller over store avstander.</li>" +
        "<li><b>Administrative og Juridiske Aspekter:</b> Oppdraget kan innebære en rekke administrative og juridiske aspekter, spesielt når det gjelder håndtering av objekter på vegne av offentlige etater som Skatteetaten.</li>" +
        "<li><b>Kostnadsaspekter:</b> Det må tas hensyn til kostnadene knyttet til transport og lagring. Dette inkluderer transportkostnader, leie av lagringsplass, og eventuelle tilleggstjenester som sikkerhet eller forsikring.</li>" +
        "<li><b>Kundekommunikasjon:</b> God kommunikasjon med kunden (i dette tilfellet Skatteetaten) er viktig for å sikre at alle behov og krav blir møtt på en tilfredsstillende måte.</li>" +
        "</ul>"
    },
    {
      name: "leasing",
      description: "Vi skal selge et leaset objekt for en bank.",
      description2:
        "<ul>" +
        "<li><b>Natur av Leasede Objekter:</b> Objekter som er leaset (f.eks. biler, maskineri, utstyr) er vanligvis eid av en bank eller finansinstitusjon, og leies ut til en kunde (leasingtaker) for en bestemt periode.</li>" +
        "<li><b>Avslutning av Leasingavtale:</b> Salg av et leaset objekt kan bli aktuelt dersom leasingavtalen avsluttes før den opprinnelige avtaleperioden er over. Dette kan skyldes forskjellige årsaker, som økonomiske vanskeligheter hos leasingtakeren, eller en beslutning om å oppgradere eller endre utstyret.</li>" +
        "<li><b>Bankens Rolle i Salget:</b> Siden banken eier det leasete objektet, er det deres ansvar å organisere og gjennomføre salget av objektet. Banken vil søke å maksimere avkastningen fra salget for å dekke eventuelle utestående beløp relatert til leasingavtalen.</li>" +
        "<li><b>Markedsverdivurdering:</b> Før salget, må det leasete objektet vurderes for å fastsette dets nåværende markedsverdi. Dette sikrer at salgsprisen er i tråd med objektets verdi og tilstand.</li>" +
        "<li><b>Selgeprosessen:</b> Salget kan involvere ulike kanaler, som auksjoner, direktesalg til interesserte kjøpere, eller gjennom forhandlere. Valg av salgskanal avhenger av objektets art og markedsforhold.</li>" +
        "<li><b>Håndtering av Økonomiske Forskjeller:</b> Hvis salgssummen ikke dekker det utestående beløpet på leasingavtalen, kan det oppstå en finansiell forskjell som må håndteres, enten gjennom etterbetaling fra leasingtaker eller gjennom annen finansiell ordning.</li>" +
        "<li><b>Juridiske og Kontraktsmessige Aspekter:</b> Salgsprosessen må håndteres i samsvar med gjeldende lovgivning og vilkår i leasingavtalen. Dette inkluderer rettigheter og forpliktelser både for banken og leasingtakeren.</li>" +
        "<li><b>Kundekommunikasjon:</b> Effektiv kommunikasjon med alle relevante parter, inkludert den opprinnelige leasingtakeren, potensielle kjøpere, og juridiske rådgivere, er viktig for å sikre en jevn og vellykket salgsprosess.</li></ul><p>Hovedmålet er å realisere verdien av det leasete objektet gjennom salg. Dette krever en balansert tilnærming for å sikre at bankens interesser blir ivaretatt samtidig som man overholder juridiske og kontraktsmessige forpliktelser.</p>"
    },
    {
      name: "deceased",
      description: "Vi skal selge for en bank, men debitor er død.",
      description2:
        "<ul>" +
        "<li><b>Håndtering av Avdødes Eiendeler:</b> Når en debitor dør, overføres ansvaret for deres eiendeler og gjeld til dødsboet. Dødsboet representerer den avdødes eiendom, rettigheter og forpliktelser.</li>" +
        "<li><b>Bankens Rolle:</b> Banken er en kreditor til dødsboet hvis den avdøde hadde utestående gjeld. Banken må derfor samarbeide med boets representant (ofte en bobestyrer) for å håndtere tilbakebetaling av gjelden.</li>" +
        "<li><b>Salg av Eiendeler:</b> For å dekke den avdødes gjeld, kan det bli nødvendig å selge eiendeler som tilhører dødsboet. Dette kan inkludere fast eiendom, kjøretøyer, verdipapirer, eller andre verdifulle eiendeler.</li>" +
        "<li><b>Bobestyrerens Rolle:</b> Bobestyreren, utnevnt av retten eller valgt av de etterlatte, har ansvaret for å forvalte dødsboet. Dette inkluderer å ta beslutninger om salg av eiendeler for å dekke gjeld.</li>" +
        "<li><b>Juridisk Prosess:</b> Hele prosessen må følge de juridiske rammene som gjelder for håndtering av dødsbo. Dette inkluderer overholdelse av arvelovgivning og gjeldsoppgjør.</li>" +
        "<li><b>Markedsverdivurdering:</b> Eiendelene som skal selges må vurderes for å fastsette deres markedsverdi. Dette sikrer at salget gjenspeiler en rettferdig verdi og bidrar til å maksimere de midlene som er tilgjengelige for å dekke gjelden.</li>" +
        "<li><b>Selgeprosessen:</b> Salgsprosessen skal være transparent og rettferdig. Det kan innebære offentlige auksjoner, private salg, eller salg gjennom eiendomsmeglere eller andre fagfolk, avhengig av eiendelens art.</li>" +
        "<li><b>Fordeling av Midler:</b> Etter at gjelden er dekket, distribueres eventuelle gjenværende midler til de berettigede arvingene i henhold til testamentet eller arveloven.</li>" +
        "</ul>"
    },
    {
      name: "foreclosure",
      description: "Salg for namsmannen.",
      description2:
        "<ul>" +
        "<li><b>Definisjon:</b> Tvangssalg refererer til salg av eiendeler som utføres av namsmannen, en offentlig myndighet som håndhever juridiske krav.</li>" +
        "<li><b>Mislighold:</b> Tvangssalg initieres vanligvis når en debitor ikke klarer å oppfylle sine økonomiske forpliktelser, som gjeldsbetaling.</li>" +
        "<li><b>Juridisk Prosess:</b> Før et tvangssalg kan finne sted, må det foreligge et lovlig grunnlag, som en rettslig avgjørelse eller et utleggspant.</li>" +
        "<li><b>Namsmannens Rolle:</b> Namsmannen er ansvarlig for å organisere og gjennomføre salget av debitors eiendeler.</li>" +
        "<li><b>Formål:</b> Målet med tvangssalg er å hente inn midler for å dekke debitors utestående gjeld til kreditorer.</li>" +
        "<li><b>Selgeprosessen:</b> Salget kan inkludere auksjoner eller andre offentlige salgsformer for å sikre en rettferdig og transparent prosess.</li>" +
        "<li><b>Fordeling av Salgsinntekter:</b> Inntektene fra salget brukes først til å dekke kostnader knyttet til salgsprosessen, deretter til å betale utestående gjeld.</li>" +
        "<li><b>Rettslige Restriksjoner:</b> Tvangssalg er underlagt strenge juridiske restriksjoner og prosedyrer for å sikre en rettferdig behandling av debitor og kreditorer.</li>" +
        "</ul>"
    },
    {
      name: "commission",
      description: "Salg for NAV og andre private bedrifter eller personer.",
      description2:
        "<ul>" +
        "<li><b>Kommisjonssalg:</b> Salget utføres av en kommisjonær (selger) som handler på vegne av en annen part (kommitent), som kan være NAV, private bedrifter, eller individuelle personer.</li>" +
        "<li><b>Rolle som Mellommann:</b> Kommisjonæren fungerer som en mellommann og tar ikke direkte eierskap til varene, men selger dem for kommitentens regning.</li>" +
        "<li><b>Klienter:</b> Klientene kan variere fra offentlige etater som NAV, som kan ha behov for å selge eiendeler for sine brukere, til private selskaper og enkeltpersoner som ønsker å selge diverse varer.</li>" +
        "<li><b>Provisjon:</b> Kommisjonæren tjener vanligvis penger gjennom en provisjon, som er en prosentandel av salgssummen.</li>" +
        "<li><b>Selgeprosessen:</b> Kommisjonæren står for hele salgsprosessen, inkludert markedsføring, forhandling med kjøpere, og gjennomføring av salget.</li>" +
        "<li><b>Juridiske Avtaler:</b> Det er ofte en formell avtale mellom kommisjonæren og kommitenten som definerer vilkår for salget, provisjonsstruktur, og andre ansvar.</li>" +
        "<li><b>Markeds- og Verdivurdering:</b> Kommisjonæren må vurdere markedet og fastsette en passende salgspris for å maksimere inntektene og tilfredsstille kommitentens forventninger.</li>" +
        "<li><b>Rapportering og Overføring av Inntekter:</b> Etter salget overføres inntektene til kommitenten, minus kommisjonærens provisjon og eventuelle andre avtalte kostnader.</li>" +
        "</ul>"
    },
    {
      name: "companyOwned",
      description: "Salg av Stadssalg AS sine eiendeler, gjerne firmabiler.",
      description2:
        "<ul>" +
        "<li><b>Eierskap:</b> Stadssalg AS selger eiendeler som de selv eier, slik som firmabiler, kontorutstyr, eller annet driftsmateriell.</li>" +
        "<li><b>Formål med Salget:</b> Salget kan være motivert av ulike årsaker som oppdatering av utstyr, behov for å frigjøre kapital, eller endringer i bedriftens operasjoner.</li>" +
        "<li><b>Selgeprosessen:</b> Firmaet håndterer selv hele salgsprosessen, inkludert markedsføring av eiendelene, forhandling med potensielle kjøpere, og gjennomføring av salgstransaksjoner.</li>" +
        "<li><b>Verdivurdering:</b> En nøye vurdering av eiendelenes verdi er nødvendig for å fastsette en passende salgspris som reflekterer deres nåværende markedsverdi.</li>" +
        "<li><b>Finansiell Rapportering:</b> Salget av egeneide eiendeler må korrekt reflekteres i firmaets finansielle rapporter, inkludert gevinst eller tap fra salget.</li>" +
        "<li><b>Skattemessige Konsekvenser:</b> Det kan være skattemessige konsekvenser knyttet til salget av egeneide eiendeler, avhengig av salgsgevinsten og regelverket.</li>" +
        "<li><b>Kundekommunikasjon:</b> God kommunikasjon og kundeservice er viktig, særlig når man selger eiendeler direkte til andre bedrifter eller privatpersoner.</li>" +
        "<li><b>Overholdelse av Lovgivning:</b> Salget må utføres i samsvar med gjeldende lover og forskrifter, spesielt de som gjelder salg av kjøretøy og kommersielt utstyr.</li>" +
        "</ul>"
    },
    {
      name: "appraisalBailiff",
      description:
        "Vi har fått i oppdrag av namsmannen og taksere et objekt. Selve takseringen skjer av Viking Kontroll.",
      description2:
        "<ul>" +
        "<li><b>Oppdragskilde:</b> Oppdraget med å taksere et objekt kommer fra namsmannen, som ofte håndterer saker knyttet til gjeldsinnkreving eller tvangsfullbyrdelse.</li>" +
        "<li><b>Rolleavklaring:</b> Din rolle (eller firmaets rolle) i dette tilfellet kan være å koordinere takseringsprosessen, fungere som et bindeledd mellom namsmannen og Viking Kontroll, og sikre at takseringen utføres i henhold til oppdragets krav.</li>" +
        "<li><b>Utførelse av Taksering:</b> Selv om takseringen utføres av Viking Kontroll, en spesialisert tredjepart, er det viktig å sikre at de har all nødvendig informasjon og tilgang til objektet for en nøyaktig vurdering.</li>" +
        "<li><b>Målet med Takseringen:</b> Taksten skal gi en nøyaktig verdivurdering av objektet, noe som er viktig for namsmannens videre håndtering av saken, enten det gjelder salg, tilbakelevering, eller annen juridisk prosess.</li>" +
        "<li><b>Rapportering:</b> Etter at takseringen er fullført, skal en takstrapport vanligvis leveres til namsmannen. Rapporten bør inneholde detaljerte opplysninger om objektets tilstand, verdi og eventuelle andre relevante faktorer.</li>" +
        "<li><b>Kommunikasjon og Samarbeid:</b> Effektiv kommunikasjon og samarbeid med alle involverte parter, inkludert namsmannen, Viking Kontroll, og eventuelle andre interessenter, er nøkkelen til en vellykket takseringsprosess.</li>" +
        "<li><b>Overholdelse av Standarder:</b> Takseringen må utføres i samsvar med profesjonelle og juridiske standarder for å sikre at vurderingen er nøyaktig og pålitelig.</li>" +
        "<li><b>Konfidensialitet og Etikk:</b> Det er viktig å håndtere oppdraget med høy grad av konfidensialitet og etiske standarder, spesielt gitt den sensitive naturen til saker som involverer namsmannen.</li>" +
        "</ul>"
    },
    {
      name: "lostnfound",
      description: "Vi selger hittegods på vegne av politi eller namsmann.",
      description2:
        "<ul>" +
        "<li><b>Hittegodsdefinisjon:</b> Hittegods refererer til gjenstander som er funnet og overlevert til myndighetene (politi eller namsmann), og som eieren ikke har kravet innen en viss tidsperiode.</li>" +
        "<li><b>Myndighetenes Rolle:</b> Politiet eller namsmannen håndterer opprinnelig mottak og registrering av hittegods og forsøker å identifisere og kontakte eierne.</li>" +
        "<li><b>Salgsprosess:</b> Hvis eieren ikke henter hittegodset innen den angitte fristen, blir du som oppdragstaker ansvarlig for å organisere salget av disse gjenstandene.</li>" +
        "<li><b>Verdivurdering:</b> Før salget, må hittegodset vurderes for å fastsette en rimelig salgspris, basert på objektenes tilstand og markedsverdi.</li>" +
        "<li><b>Salgskanaler:</b> Salget kan foregå gjennom ulike kanaler, som auksjoner (fysiske eller online), direktesalg, eller via spesialiserte salgssteder for hittegods.</li>" +
        "<li><b>Transparens og Lovgivning:</b> Salget må utføres på en transparent måte og i samsvar med gjeldende lover og regler som gjelder for håndtering og salg av hittegods.</li>" +
        "<li><b>Omfordeling av Inntekter:</b> Inntektene fra salget håndteres i henhold til spesifikke regler, hvor deler av inntektene ofte går til statskassen, mens en andel kan tilbakebetales til finneren under visse omstendigheter.</li>" +
        "<li><b>Kommunikasjon med Interessenter:</b> God kommunikasjon med alle relevante parter, inkludert politi, namsmann, potensielle kjøpere og finnere av hittegodset, er essensielt for en vellykket prosess.</li>" +
        "</ul>"
    },

    {
      name: "bankruptAdmin",
      description:
        "Vi er engasjert av bostyrer for å selge objekter for konkursboet.",
      description2:
        "<ul>" +
        "<li><b>Rolle av Bobestyrer:</b> En bobestyrer er en juridisk utnevnt person som håndterer forvaltningen av et konkursbo, som omfatter debitors eiendeler og gjeld.</li>" +
        "<li><b>Engasjement for Salgsoppgaver:</b> Du eller ditt firma er engasjert av bobestyreren spesifikt for å selge objekter som tilhører konkursboet.</li>" +
        "<li><b>Målet med Salget:</b> Hovedmålet er å realisere verdier fra konkursboets eiendeler for å betale tilbake kreditorene i henhold til prioritetsrekkefølgen etablert i konkurslovgivningen.</li>" +
        "<li><b>Verdivurdering:</b> En nøye vurdering av objektenes verdi er essensiell for å fastsette en passende salgspris og maksimere inntektene til konkursboet.</li>" +
        "<li><b>Selgeprosessen:</b> Salgsprosessen kan inkludere offentlige auksjoner, private salg, eller andre metoder for å sikre en effektiv og rettferdig prosess.</li>" +
        "<li><b>Juridiske Prosedyrer:</b> Salget må utføres i henhold til konkurslovgivningen og andre relevante juridiske rammer, og det krever ofte godkjenning fra bobestyrer eller domstolen.</li>" +
        "<li><b>Transparent og Etisk Håndtering:</b> Det er viktig å håndtere salget transparent og etisk for å bevare integriteten til konkursprosessen.</li>" +
        "<li><b>Rapportering til Bobestyrer:</b> Regelbundet rapportering til bobestyreren om salgsprosessen og -resultatene er nødvendig for å sikre god forvaltning av konkursboet.</li>" +
        "<li><b>Fordeling av Salgsinntekter:</b> Etter salget skal inntektene fordeles i henhold til de retningslinjer som er fastsatt av bobestyrer og gjeldende lovverk.</li>" +
        "</ul>"
    },
    {
      name: "bankruptAuth",
      description:
        "Vi er engasjert av en bank, men selger i navnet til den konkursrammede bedriften via en salgsfullmakt.",
      description2:
        "<ul>" +
        "<li><b>Salgsfullmakt:</b> Du mottar en fullmakt fra banken som gir deg autorisasjon til å selge eiendeler på vegne av den konkursrammede bedriften.</li>" +
        "<li><b>Bankens Rolle:</b> Banken, som kreditor, har ofte en sikkerhetsinteresse i eiendelene til bedriften som er konkurs og søker å realisere disse verdiene for å dekke utestående gjeld.</li>" +
        "<li><b>Salg i Bedriftens Navn:</b> Selv om du er engasjert av banken, foregår salget under navnet til den konkursrammede bedriften. Dette krever klar kommunikasjon og transparens for å unngå forvirring.</li>" +
        "<li><b>Verdivurdering:</b> En viktig del av oppdraget er å vurdere verdien av de aktuelle eiendelene for å fastsette riktige salgspriser.</li>" +
        "<li><b>Selgeprosessen:</b> Salgsprosessen bør være effektiv og rettferdig, og kan inkludere metoder som auksjoner, direktesalg, eller bruk av spesialiserte salgskanaler.</li>" +
        "<li><b>Juridiske Krav:</b> Salget må håndteres i samsvar med juridiske krav og regler som gjelder for salg av eiendeler under konkurs, samt i henhold til vilkårene i salgsfullmakten.</li>" +
        "<li><b>Rapportering og Overføring av Inntekter:</b> Nøyaktig rapportering av salgsprosessen og inntekter til banken er essensielt, og inntektene fra salget skal brukes til å nedbetale gjelden i henhold til bankens krav.</li>" +
        "<li><b>Etiske Hensyn:</b> Det er viktig å håndtere salget med et høyt nivå av etikk og profesjonalitet, spesielt gitt de sensitive aspektene ved konkurs.</li>" +
        "<li><b>Kommunikasjon med Interessenter:</b> Åpen og tydelig kommunikasjon med alle relevante parter, inkludert den konkursrammede bedriften, potensielle kjøpere, og juridiske rådgivere, er nødvendig.</li>" +
        "</ul>"
    },
    {
      name: "exclusion",
      description:
        "Dette er salg for namsmann hvor man samler inn objekter fra et sted hvor debitor er pålagt å flytte fra grunnet manglende betaling.",
      description2:
        "<ul>" +
        "<li><b>Fravikelsesprosess:</b> Fravikelse innebærer at debitor må forlate og fravike en eiendom, vanligvis som følge av manglende betalinger, som husleie eller lån.</li>" +
        "<li><b>Namsmannens Rolle:</b> Namsmannen gjennomfører fravikelsen ved å håndheve en rettslig avgjørelse, og har ansvar for å fjerne debitors eiendeler fra eiendommen.</li>" +
        "<li><b>Innsamling av Objekter:</b> Som en del av fravikelsen, samles objekter som tilhører debitor inn for eventuelt salg eller oppbevaring.</li>" +
        "<li><b>Selgeprosessen:</b> Du er ansvarlig for å selge disse objektene, ofte for å dekke deler av debitors gjeld eller utestående forpliktelser.</li>" +
        "<li><b>Verdivurdering:</b> Objektene må vurderes for å fastsette en passende salgspris. Dette er viktig for å maksimere inntektene og rettferdig dekke gjelden.</li>" +
        "<li><b>Salgskanaler:</b> Salget kan foregå gjennom ulike kanaler, inkludert offentlige auksjoner eller private salg, avhengig av objektenes natur og verdi.</li>" +
        "<li><b>Juridiske Prosedyrer:</b> Salget må utføres i tråd med juridiske prosedyrer og etiske retningslinjer, spesielt gitt de følsomme omstendighetene rundt fravikelse.</li>" +
        "<li><b>Kommunikasjon med Interessenter:</b> Effektiv kommunikasjon med alle involverte parter, inkludert namsmannen, potensielle kjøpere, og debitor, er nødvendig.</li>" +
        "<li><b>Transparens og Ansvarlighet:</b> Det kreves en høy grad av transparens og ansvarlighet gjennom hele prosessen, fra innsamling av objekter til det endelige salget.</li>" +
        "</ul>"
    },
    {
      name: "outreach",
      description:
        "Vi jobber med saker for enten namsmannen eller inkassoselskapene for å få debitor til å betale eller levere fra seg et objekt.",
      description2:
        "<ul>" +
        "<li><b>Formål med Oppdraget:</b> Hovedformålet er å oppsøke debitor for å enten innkreve betaling eller sikre tilbakelevering av et objekt som sikkerhet for en gjeld.</li>" +
        "<li><b>Samarbeid med Namsmannen eller Inkassoselskaper:</b> Oppdraget utføres på vegne av namsmannen eller inkassoselskaper, som har juridisk myndighet til å kreve inn gjeld eller sikre tilbakelevering av objekter.</li>" +
        "<li><b>Oppsøkende Virksomhet:</b> Oppdraget innebærer direkte kontakt med debitorer, vanligvis ved å besøke deres hjem eller forretningslokaler for å diskutere gjeldssituasjonen og finne løsninger.</li>" +
        "<li><b>Forhandling og Kommunikasjon:</b> Effektive forhandlingsevner og klar kommunikasjon er avgjørende for å oppnå ønskede resultater, enten det er betaling av gjeld eller tilbakelevering av objekter.</li>" +
        "<li><b>Juridiske og Etiske Rammer:</b> Arbeidet må utføres innenfor de juridiske og etiske rammene som gjelder for gjeldsinnkreving og tilbakelevering av eiendeler.</li>" +
        "<li><b>Konflikthåndtering:</b> Det er viktig å kunne håndtere eventuelle konflikter som kan oppstå under oppsøk, og jobbe mot en fredelig og konstruktiv løsning.</li>" +
        "<li><b>Dokumentasjon:</b> Nøyaktig dokumentasjon av alle interaksjoner, avtaler og transaksjoner er essensielt for juridisk etterlevelse og som bevis i eventuelle fremtidige disputter.</li>" +
        "<li><b>Respekt for Debitor:</b> Det er viktig å opprettholde en profesjonell og respektfull holdning overfor debitorer, selv i vanskelige situasjoner.</li>" +
        "<li><b>Overholdelse av Prosedyrer:</b> Strikt overholdelse av fastsatte prosedyrer for inndrivelse og tilbakelevering er nødvendig for å sikre lovlighet og rettferdighet i prosessen.</li>" +
        "</ul>"
    }
  ]
};

export default {
  status,
  valuationSupplier,
  accountingCategory,
  repossessionType,
  preparationFiles,
  infoFiles,
  valuationFiles,
  saleFiles,
  economyFiles,
  museumFiles
};

import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    meta: {
      title: "Dashboard",
      agentAllowed: false
    },
    path: "/",
    name: "home",
    component: Home,
    props: true
  },
  {
    meta: {
      title: "Dashboard",
      agentAllowed: false
    },
    path: "/dashboard/:tab?",
    name: "dashboard",
    component: Home,
    props: true
  },
  {
    meta: {
      title: "Users"
    },
    path: "/users",
    name: "users",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/users/List.vue")
  },
  {
    meta: {
      title: "New user"
    },
    path: "/users/new/:companyId?",
    name: "user.new",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/users/Edit.vue")
  },
  {
    meta: {
      title: "Edit User",
      agentAllowed: true
    },
    path: "/user/:id",
    name: "user.edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/users/Edit.vue"),
    props: true
  },
  {
    meta: {
      title: "Oppdragsgivere"
    },
    path: "/agents",
    name: "agents",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/List.vue")
  },
  {
    meta: {
      title: "Ny oppdragsgiver"
    },
    path: "/agents/new",
    name: "agents.new",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/Edit.vue")
  },
  {
    meta: {
      title: "Rediger oppdragsgiver"
    },
    path: "/agents/:id",
    name: "agents.edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/Edit.vue"),
    props: true
  },
  {
    meta: {
      title: "Inkassobyrå"
    },
    path: "/dcas",
    name: "dcas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/List.vue")
  },
  {
    meta: {
      title: "Nytt inkassobyrå"
    },
    path: "/dcas/new",
    name: "dca.new",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/Edit.vue")
  },
  {
    meta: {
      title: "Rediger inkassobyrå"
    },
    path: "/dcas/:id",
    name: "dcas.edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/Edit.vue"),
    props: true
  },
  {
    meta: {
      title: "Firmaer"
    },
    path: "/companies",
    name: "companies",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/List.vue")
  },
  {
    meta: {
      title: "Nytt firma"
    },
    path: "/companies/new",
    name: "company.new",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/Edit.vue")
  },
  {
    meta: {
      title: "Nytt firma"
    },
    path: "/companies/new/:companyApiEndPoint",
    name: "company.new.endpoint",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/Edit.vue"),
    props: true
  },
  {
    meta: {
      title: "Rediger firma"
    },
    path: "/companies/:id",
    name: "companies.edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/companies/Edit.vue"),
    props: true
  },
  {
    meta: {
      title: "Konkurser"
    },
    path: "/bankruptcies",
    name: "bankruptcies",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/bankruptcies/List.vue")
  },
  {
    meta: {
      title: "Ny konkurs"
    },
    path: "/bankruptcies/new",
    name: "bankruptcies.new",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/bankruptcies/Edit.vue")
  },
  {
    meta: {
      title: "Rediger konkurs"
    },
    path: "/bankruptcies/:id",
    name: "bankruptcies.edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/bankruptcies/Edit.vue"),
    props: true
  },
  {
    meta: {
      title: "Rapporter",
      agentAllowed: true
    },
    path: "/reports",
    name: "reports",
    component: () => import("@/views/reports/Index.vue"),
    redirect: { name: "reports-new" },
    children: [
      {
        path: "new",
        name: "reports-new",
        meta: { agentAllowed: true },
        component: () => import("@/views/reports/components/New.vue")
      },
      {
        path: "special",
        name: "reports-special",
        component: () => import("@/views/reports/components/Special.vue")
      },
      {
        path: "saved",
        name: "reports-saved",
        component: () => import("@/views/reports/components/Saved.vue")
      }
    ]
  },
  {
    meta: {
      title: "Avdelinger",
      agentAllowed: false
    },
    path: "/locations",
    name: "locations",
    component: () => import("../views/locations/Index.vue")
  },

  {
    meta: {
      title: "Items",
      agentAllowed: true
    },
    path: "/items",
    name: "items",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/items/List.vue")
  },
  {
    meta: {
      title: "Image Dumper"
    },
    path: "/items/imageDumper/:id?",
    name: "item.imageDumper",
    component: () => import("../views/full-page/ImageDumper.vue")
  },
  {
    meta: {
      title: "New Item",
      agentAllowed: false
    },
    path: "/items/new/:id?",
    name: "item.new",
    component: () => import("../views/items/New.vue")
  },
  {
    meta: {
      title: "New Item",
      agentAllowed: true
    },
    path: "/items/agent/new/:id?",
    name: "agent.item.new",
    component: () => import("../views/items/AgentNew.vue")
  },
  {
    meta: {
      title: "Edit Item",
      agentAllowed: true
    },
    path: "/item/:id",
    name: "item.edit",
    component: () => import("../views/items/Edit.vue"),
    children: [
      {
        path: "preparation",
        name: "item.edit.preparation",
        component: () => import("../views/items/components/Preparation.vue"),
        props: true
      },
      {
        path: "info",
        name: "item.edit.info",
        component: () => import("../views/items/components/Info.vue"),
        props: true
      },
      {
        path: "valuation",
        name: "item.edit.valuation",
        component: () => import("../views/items/components/Valuation.vue"),
        props: true
      },
      {
        path: "sale",
        name: "item.edit.sale",
        component: () => import("../views/items/components/Sale.vue"),
        props: true
      },
      {
        path: "economy",
        name: "item.edit.economy",
        component: () => import("../views/items/components/Economy.vue"),
        props: true
      },
      {
        path: "museum",
        name: "item.edit.museum",
        component: () => import("../views/items/components/Museum.vue"),
        props: true
      },
      {
        path: "modules",
        name: "item.edit.modules",
        component: () => import("../views/items/components/Modules.vue"),
        props: true
      },
      {
        path: "log",
        name: "item.edit.log",
        component: () => import("../views/items/components/Log.vue"),
        props: true
      }
    ],
    props: true
  },
  {
    meta: {
      title: "Profile",
      agentAllowed: true
    },
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue")
  },
  {
    meta: {
      title: "Logg",
      agentAllowed: false
    },
    path: "/logs",
    name: "logs",
    component: () => import("../views/Log.vue")
  },
  {
    path: "/full-page",
    component: () => import("../views/FullPage.vue"),
    children: [
      {
        meta: {
          title: "Login",
          isAnonymous: true
        },
        path: "/login",
        name: "login",
        component: () => import("../views/full-page/Login.vue")
      },
      {
        meta: {
          title: "Logout",
          isAnonymous: false
        },
        path: "/logout",
        name: "logout",
        props: true,
        component: () => import("../views/full-page/Logout.vue")
      },
      {
        meta: {
          title: "Password Recovery",
          isAnonymous: true
        },
        path: "/password-recovery",
        name: "password-recovery",
        component: () => import("../views/full-page/PasswordRecovery.vue")
      },
      {
        meta: {
          title: "Error v.1"
        },
        path: "/error-in-card",
        name: "error-in-card",
        component: () => import("../views/full-page/Error.vue")
      },
      {
        meta: {
          title: "Error v.2"
        },
        path: "/error-simple",
        name: "error-simple",
        component: () => import("../views/full-page/Error.vue"),
        props: { isInCard: false }
      },
      {
        meta: {
          title: "Lock screen"
        },
        path: "/lock-screen",
        name: "lock-screen",
        component: () => import("../views/full-page/LockScreen.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Stadssalg";

  const isAnonymous = to.matched.some(record => record.meta.isAnonymous);

  if (!isAnonymous) {
    const loginInterval = setInterval(() => {
      if (store.getters.getLoginStatus == "loggedIn") {
        // check if role is admin
        store.getters.getUser.getIdTokenResult().then(idTokenResult => {
          if (
            !(
              idTokenResult.claims.role == "admin" ||
              idTokenResult.claims.role == "super" ||
              idTokenResult.claims.role == "agent"
            )
          ) {
            next({
              path: "/logout",
              query: { error: "Du har ikke tilgang." }
            });
          } else if (
            idTokenResult.claims.role == "agent" &&
            !to.meta.agentAllowed
          ) {
            next({
              path: "/items"
            });
          }
        });

        clearInterval(loginInterval);
        next();
      } else if (store.getters.getLoginStatus == "loggedOut") {
        clearInterval(loginInterval);
        next({
          path: "/login",
          query: { nextUrl: to.fullPath }
        });
      }
    }, 100);
  } else {
    next();
  }
});

export default router;
